import React from 'react';
import Plugins from 'views/Plugins';
import Main from 'layouts/Main';
import WithLayout from 'WithLayout';

const PluginsPage = () => {
  return (
    <WithLayout
      component={Plugins}
      layout={Main}
    />
  )
};

export default PluginsPage;
